.form-control-feedback {
	display:none;
}

select#faculty-tag-dropdown {
	padding-right:0px;
}

header.navbar {
	background:#fff;
	filter:none !important;
}

.content-contact-card {
  margin: 60px 0px 140px;  
}


.contact-card{

  font-size:12px; 
  font-family:"Montserrat-bold";
  margin-top:10px;
  margin-bottom:40px;
  @media (min-width:768px) {
    height:180px;
  }
  @media (max-width:768px) {
    margin-bottom:20px !important;
  }
  @media (max-width:480px) {
    .row {
      .col-xs-3 {
        padding-right:0px;
        padding-left:5px; 
      }
      .col-xs-9 {
        padding-right:0px;
      }
    }
  }
  img {
    width:100%;
    max-width:98px;
    height:auto;
  }
  .institution{
    margin-bottom: 12px;
  }

  h4{
    margin-top: 0px;
    font-size:14px;
    text-transform:uppercase;
    font-family:'Montserrat-bold';
    margin-bottom:5px;
  }

  h4 a{
    color: #4c4c4c;
  }
  .card-phone, .card-email {
    font-size:11px;
    font-family:'Montserrat';
    a {
      color:#4c4c4c;
      text-decoration:none;
      &:hover {
        text-decoration:underline;
      }
    }
  }

}